import { useTranslation } from "react-i18next";
import { Card } from "../../../components/cards/Card";
import { Store } from "../../../data/dataMerchant";
import { AuthorizationFees } from "./SurchargesAuthFees/AuthorizationFees";

import { Surcharges } from "./SurchargesAuthFees/Surcharges";
import { TerminalPricing } from "./TerminalPricing/TerminalPricing";
import { TransactionFees } from "./TransactionFees/TransactionFees";
import { CashlessPromotion } from "./CashlessPromotion";
import { PricingModelDescription } from "./PricingModelDescription/PricingModelDescription";
import {
  ContractPricing,
  PricingModel as PricingModelEnum,
} from "../../../data/models/PricingTypes";
import { PackPricing } from "./PackPricing/PackPricing";
import styles from "./AdvancedPricing.module.scss";
import SteppedBasedPricing from "./SteppedBasedPricing/SteppedBasedPricing";
import { getIntlCost } from "../../../components/utils";
import { CURRENCY_BY_COUNTRY } from "../../../i18n";
import { Country, Language } from "../../../data/models/ContractTypes";
import { FC } from "react";
import PomcaSummary from "./PomcaSummary/PomcaSummary";
import ArticlesTable from "./ArticlesTable/ArticlesTable";

interface Props {
  pricing: ContractPricing;
  stores: Store[];
  country: Country;
}

export const InstorePricing: FC<Props> = ({ pricing, country, stores }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as Language;

  const {
    transactionFees,
    chargebackFee,
    surchargesCommercialCards,
    surchargesNonEea,
    preAuthorizationFees,
    cashlessPromotion,
    pricingModel,
    name,
    steppedBasedPricing,
    installationFee,
    acceptance,
    pomca,
  } = pricing;

  return (
    <Card header={t("Pricing")}>
      <div className={styles.root}>
        <PricingModelDescription model={pricingModel} name={name} contractPricing={pricing} />

        {acceptance && (
          <>
            {pricingModel === PricingModelEnum.PACK ? (
              <PackPricing contractPricing={pricing} country={country} />
            ) : (
              <TerminalPricing stores={stores} country={country} />
            )}
          </>
        )}

        {installationFee && (
          <div>
            <div className="section-subtitle">{t("Terminals installation fee")}</div>
            <div className={styles.installationFee}>
              <div className="fw-500">{t("Total")}</div>
              <div className="fw-600">
                {getIntlCost(language, installationFee, CURRENCY_BY_COUNTRY[country])}
              </div>
            </div>
          </div>
        )}

        <div>
          {steppedBasedPricing ? (
            <SteppedBasedPricing steppedBasedPricing={steppedBasedPricing} country={country} />
          ) : (
            <TransactionFees
              country={country}
              transactionFees={transactionFees}
              pricingModel={pricingModel}
            />
          )}

          <div className={styles.authfees_surcharges}>
            {preAuthorizationFees && <AuthorizationFees />}
            <Surcharges
              country={country}
              chargebackFee={chargebackFee}
              surchargesCommercialCards={surchargesCommercialCards}
              surchargesNonEea={surchargesNonEea}
            />
          </div>
          {!!pomca && <PomcaSummary country={country} pomca={pomca} />}
          <ArticlesTable />
        </div>

        {cashlessPromotion && <CashlessPromotion country={country} name={name} />}
      </div>
    </Card>
  );
};
