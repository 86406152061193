import React, { useEffect } from "react";
import ReactModal from "react-modal";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import "./Modal.scss";

ReactModal.setAppElement("#root");

interface Props {
  showModal: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  width?: number;
  className?: string;
  closeOnOverlayClick?: boolean;
}

export const Modal: React.FunctionComponent<Props> = ({
  showModal,
  onClose,
  children,
  width = 700,
  className,
  closeOnOverlayClick = false,
}) => {
  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const afterCloseHandler = () => enableBodyScroll(document.body);

  const afterOpenHandler = () => {
    disableBodyScroll(document.body, {
      reserveScrollBarGap: true,
    });
  };

  return (
    <ReactModal
      className={className}
      overlayClassName="modal"
      closeTimeoutMS={250}
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: "rgba(242, 242, 242, 0.75)",
        },
        content: {
          padding: 0,
          position: "initial",
          width,
          border: 0,
          borderRadius: 10,
          margin: 10,
        },
      }}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      onAfterClose={afterCloseHandler}
      onAfterOpen={afterOpenHandler}
    >
      <div className="modal-body">
        <div>{children}</div>
      </div>
    </ReactModal>
  );
};
