import { Trans, useTranslation } from "react-i18next";
import { Link } from "../../../components/link/Link";
import { Country } from "../../../data/models/ContractTypes";

import "./CashlessPromotion.scss";

interface Props {
  country: Country;
  name: string;
}

const CASHLESS_LINK_CZ =
  "https://www.payphone.cz/wp-content/uploads/2022/04/Podminky-nabidky-%E2%80%9ECesko-plati-kartou-100-sleva-na-12-mesicu-PayPhone.pdf";

const CASHLESS_LINK_CZ_2 = "https://www.kbsmartpay.cz/klientska-zona/ke-stazeni/";
export const CashlessPromotion = ({ country, name }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="cashless-promotion">
      <h5 className="section-subtitle">{t("Cashless payments")}</h5>
      <p style={{ margin: 0 }}>
        {country === Country.CZECHIA ? (
          name !== "POS - MPO" ? (
            <Trans>
              I have applied to the government program and benefit from 100% discount on fees from
              transactions made via the PayPhone application up to CZK 50,000 (EUR 2,000) per month
              for 12 months. I agree with the{" "}
              <Link link={CASHLESS_LINK_CZ} external>
                conditions of the special offer
              </Link>
              .
            </Trans>
          ) : (
            <Trans>
              I applied for the "Czech Republic pays by card" offer and I am using a 100% discount
              on fees for transactions made up to CZK 50,000 per month for a period of 12 months. I
              agree to the terms of the offer
              <Link link={CASHLESS_LINK_CZ_2} external>
                Terms of the offer Czech Republic pays with a card 100% discount for 12 months
              </Link>
              .
            </Trans>
          )
        ) : (
          <Trans>I have applied to the government program.</Trans>
        )}
      </p>
    </div>
  );
};
