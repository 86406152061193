import { useState, useEffect, useRef } from "react";

const getWidth = () => window.innerWidth || document.body.clientWidth;

export function useWidth() {
  let [width, setWidth] = useState(getWidth());
  const timer = useRef<number | null>(null);

  useEffect(() => {
    const resizeListener = () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(() => setWidth(getWidth()), 300);
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return width;
}
